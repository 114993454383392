import {
  useContext,
  useRef,
  useEffect,
  useState,
  useMemo,
  Fragment,
  useLayoutEffect,
} from 'react';
import { useParams } from 'react-router-dom';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
import { socketContext } from 'context/WebSocketProvider/SocketProvider';
// Components/ui
import { Box, LinearProgress, useTheme } from '@mui/material';
import {
  MessageItem,
  Date,
  NewMessages,
  ArrowDownButton,
  NewMessagesLabel,
} from './components';
import { style } from './style';
// Types
import { IMessage, MessageType } from '@trii/types/dist/Common/Messages';
import { Message } from 'features/Views/Conversations/context/MessagesProvider/types/Message';
// Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useSelector } from 'react-redux';
// Selector
import {
  selectMessagesFetchStatus,
  selectNextMessagesFetchStatus,
} from 'redux/features/messagesSlice/messagesSlice';
// Moment
import moment from 'moment';
// DB
import { useLiveQuery } from 'dexie-react-hooks';
import db, { dbWorker } from 'db/db';
import {
  readConversation,
  setConversationSelected,
} from 'redux/features/conversationsSlice/conversationsSlice';
import { ChatType, IConversation } from '@trii/types/dist/Conversations';

const FORMAT_DATE = 'DD/MM/YYYY';

type BodyProps = {
  conversationSelected: IConversation;
};

const Body = ({ conversationSelected }: BodyProps) => {
  const theme = useTheme();

  const dispatch = useAppDispatch();

  const { contactId } = useParams<{ contactId: string }>();

  const messagesFetchStatus = useSelector(selectMessagesFetchStatus);
  const nextMessagesFetchStatus = useSelector(selectNextMessagesFetchStatus);

  const {
    selectMessagesMode,
    selectedMessages,
    conversations,
    setSelectedMessages,
    setIsEmailMode,
    setSelectMessagesMode,
    getContact,
  } = useContext(conversationsContext);
  const {
    backgroundImage,
    messages,
    getNewMessages,
    getNextNewMessages,
    setFooterSize,
    setMessages,
    infoMessagesHandler,
    setFrom,
    setTo,
    setCc,
    setBcc,
    setSubjectDraft,
    setBodyDraft,
    setAttachments,
    handleOpenEmailMode,
    setTextDraft,
    setEmailConversationHasDraft,
  } = useContext(messagesContext);
  const { subscribeEvent, socketConnection, unsubscribeEvent } =
    useContext(socketContext);

  const messagesDb = useLiveQuery(
    async () =>
      conversationSelected &&
      (await db.messages
        .where('contactId')
        .equals(conversationSelected?.contactInfo.id)
        .toArray()),
    [conversationSelected?.id, db.messages]
  );

  const conversationSelectedRef = useRef(conversationSelected);
  const containerRef = useRef<HTMLDivElement>(null);
  const messageContainerRef = useRef<HTMLDivElement>(null);
  const messagesRef = useRef<Message[]>([]);

  messagesRef.current = messages;

  const today = moment();
  const todayFormat = today.format(FORMAT_DATE);
  const yesterday = today.subtract(1, 'days').format(FORMAT_DATE);
  const isNextLoading = nextMessagesFetchStatus === 'loading';

  const [filesDoneLoading, setFilesDoneLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [lastMessageId, setLastMessageId] = useState<string>('');
  const [isAllLoaded, setIsAllLoaded] = useState<boolean>(false);
  const [loadedMoreMessages, setLoadedMoreMessages] = useState<{
    status: boolean;
    scrollPosition: number;
    scrollHeight: number;
  }>({
    status: false,
    scrollPosition: 0,
    scrollHeight: 0,
  }); // Data used to keep track of the scroll bar position when loading more messages
  const [conversationsAllLoaded, setConversationsAllLoaded] = useState<string[]>([]);
  const [newMessageCount, setNewMessageCount] = useState(0);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [isScrollAtBottom, setIsScrollAtBottom] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);
  // Unread messages state
  const [unreadMessagesIds, setUnreadMessagesIds] = useState<string[]>([]);
  const [firstUnreadMessageId, setFirstUnreadMessageId] = useState<string>(null); // Used to render the unread messages chip in the chat

  // Functions
  const handleSelectMessage = (message: IMessage) => {
    const isMessageAdded = selectedMessages.some(
      (messageSelected) => messageSelected.id === message.id
    );
    if (selectMessagesMode && message?.type !== MessageType.N2B) {
      if (!isMessageAdded) {
        setSelectedMessages([...selectedMessages, message]);
      } else {
        setSelectedMessages(
          selectedMessages.filter(
            (messageSelected) => messageSelected.id !== message.id
          )
        );
      }
    }
  };

  const sortMessages = (messages: IMessage[]) => {
    const messagesSorted = messages.slice().sort((a, b) => {
      const dateA = moment(a.timestamp);
      const dateB = moment(b.timestamp);
      return dateA.diff(dateB);
    });

    const getLastMessageId = messagesSorted[0]?.id;

    setLastMessageId(getLastMessageId);

    return messagesSorted;
  };

  const getNewMessageList = async (conversationId: string) => {
    const newMessages = await getNextNewMessages({
      contactId: '',
      conversationId,
      pos: lastMessageId,
    });
    const oldMessagesIdsList = messages.map((messages) =>
      messages.messages.map((message) => message.id)
    );
    const oldMessagesIds = oldMessagesIdsList.flat();
    const messagesIsNews = newMessages?.filter(
      (message: IMessage) => !oldMessagesIds.includes(message.id)
    );
    return messagesIsNews;
  };

  const handleScroll = async (e: React.UIEvent<HTMLElement>) => {
    // Infinite scroll function
    e.preventDefault();
    e.stopPropagation();

    if (isNextLoading || isAllLoaded) return;

    // Scroll to top
    const top = e.currentTarget.scrollTop === 0;
    const current = e.currentTarget;

    setIsScrollAtBottom(
      current.scrollHeight - current.scrollTop === current.clientHeight ||
        current.scrollHeight - current.scrollTop < 700
    );

    if (top && !firstLoad) {
      const newMessageList = [];

      if (conversationSelected) {
        setLoadedMoreMessages({
          status: true,
          scrollPosition: current.scrollTop,
          scrollHeight: current.scrollHeight,
        });
        const messagesList = await getNewMessageList(conversationSelected.id);

        if (messagesList) {
          newMessageList.push(...messagesList);
        }
      } else {
        const conversationList = conversations.filter(
          (conversation) => conversation.contactInfo.id === contactId
        );

        if (conversationList) {
          const conversationListSorted = conversationList.sort((a, b) => {
            const dateA = moment(a.updatedAt);
            const dateB = moment(b.updatedAt);
            return dateB.diff(dateA);
          });
          const result = await Promise.all(
            conversationListSorted.map(async (conversation, i) => {
              if (conversationsAllLoaded.includes(conversation.id)) return [];

              const messagesConversation = await getNewMessageList(conversation.id);

              if (messagesConversation && messagesConversation.length > 0) {
                return messagesConversation;
              } else {
                setConversationsAllLoaded([
                  ...conversationsAllLoaded,
                  conversation.id,
                ]);

                return [];
              }
            })
          );
          const resultList = result.filter((item) => item.length > 0).flat();
          if (resultList.length > 20) {
            const splitList = resultList.slice(0, 20);
            const lastMessage = splitList[splitList.length - 1];
            const removeLastConversation = conversationsAllLoaded.filter(
              (item) => item !== lastMessage.conversationId
            );
            setConversationsAllLoaded(removeLastConversation);
            newMessageList.push(...splitList);
          } else {
            newMessageList.push(...resultList);
          }
        }
      }
      if (newMessageList.length > 0) {
        setIsAllLoaded(false);
        const messagesSorted = sortMessages(newMessageList);
        // Group by date
        const messagesGroupByDate = messagesSorted.reduce(
          (acc: Message[], message: IMessage) => {
            const date = moment(message.timestamp).format(FORMAT_DATE);
            const index = acc.findIndex((item) => item.date === date);
            const alreadyExist = messages.findIndex((item) => item.date === date);
            if (alreadyExist !== -1) {
              const messagesSorted = sortMessages([
                ...messages[alreadyExist].messages,
                message,
              ]);
              messages[alreadyExist].messages = messagesSorted;
            } else {
              if (index === -1) {
                acc.push({
                  date,
                  messages: [message],
                });
              } else {
                acc[index].messages.push(message);
              }
            }
            return acc;
          },
          []
        );
        const updateMessageState = [...messagesGroupByDate, ...messages];
        const getLastMessageId =
          updateMessageState[updateMessageState.length - 1]?.messages[
            updateMessageState[updateMessageState.length - 1]?.messages.length - 1
          ]?.id;
        setLastMessageId(getLastMessageId);
        // @ts-ignore
        setMessages((prev) => [...messagesGroupByDate, ...prev]);
      } else {
        setIsAllLoaded(true);
      }
    }
    setFirstLoad(false);
  };

  const groupMessages = (newMessages: IMessage[]) => {
    // Sort messages by date
    const messagesSorted = sortMessages(newMessages);
    // Group by date
    const messagesGroupByDate = messagesSorted.reduce(
      (acc: Message[], message: IMessage) => {
        const date = moment(message.timestamp).format(FORMAT_DATE);
        const index = acc.findIndex((item) => item.date === date);
        if (index === -1) {
          acc.push({
            date,
            messages: [message],
          });
        } else {
          acc[index].messages.push(message);
        }
        return acc;
      },
      []
    );

    return messagesGroupByDate;
  };

  const getMessagesFromPath = async () => {
    const conversationList = conversations.filter(
      (conversation) => conversation.contactInfo.id === contactId
    );
    if (conversationList) {
      const messageList = [];
      await Promise.all(
        conversationList.map(async (conversation, i) => {
          const messagesConversation = await getNewMessages(conversation.id);
          const filterMessages = messagesConversation?.filter(
            (message) => message.conversationId === conversation.id
          );
          if (filterMessages) {
            messageList.push(...filterMessages);
          }
        })
      );
      if (messageList && messageList.length > 20) {
        const splitList = messageList.slice(0, 20);
        const messagesGrouped = groupMessages(splitList);
        return messagesGrouped;
      }
      const messagesGrouped = groupMessages(messageList);
      return messagesGrouped;
    }
  };

  const setMessagesOfContact = async () => {
    const contactMessages = await getMessagesFromPath();
    setMessages(contactMessages);
  };

  const resetConversation = () => {
    setMessages([]);
    setIsAllLoaded(false);
    setLastMessageId('');
    setConversationsAllLoaded([]);
  };

  const reactToConversationSelectedChange = async (
    conversationId: string,
    contactId: string
  ) => {
    setIsLoading(true);

    await dbWorker.postMessage({
      action: 'getAllMessages',
      data: contactId,
    });
    await getNewMessages(conversationId);

    setIsLoading(false);
  };

  const handleScrollToBottom = (event?: React.MouseEvent) => {
    event?.preventDefault();
    const current = messageContainerRef.current;
    if (current) {
      current.scrollTo({
        top: current.scrollHeight,
        behavior: 'smooth',
      });
      setNewMessageCount(0);
    }
  };

  useEffect(() => {
    conversationSelectedRef.current = conversationSelected;
  }, [conversationSelected]);

  useEffect(() => {
    // Resets the state of the conversation selected when the component unmounts, used to stop reading new messages
    return () => {
      conversationSelectedRef.current = null;
      conversationSelected = null;
    };
  }, []);

  // Subscribe to get all messages event from db web worker
  useEffect(() => {
    dbWorker.onmessage = (event) => {
      if (event.data.action === 'getAllMessages' && event.data?.data?.length > 0) {
        const messagesGrouped = groupMessages(event.data.data);
        setMessages(messagesGrouped);
      }

      if (event.data.action === 'updateMessage' && event.data.data) {
        // Get the date of the message from the timestamp attribute (its a Date object)
        const date = moment(event.data.data.timestamp).format(FORMAT_DATE);
        // Search for the object of the date in the messages array
        const index = messagesRef.current.findIndex((item) => item.date === date);
        // Get the index of the message in the array of messages of the object of the date
        const messageIndex = messagesRef.current[index]?.messages.findIndex(
          (message) => message.id === event.data.data.id
        );
        if (messageIndex) {
          // Create a copy of the messages array
          const messagesCopy = [...messagesRef.current];

          // Update the message in the copy
          messagesCopy[index].messages[messageIndex] = event.data.data;

          // Update the state with the updated copy
          setMessages(messagesCopy);
        }
      }

      if (event.data.action === 'getDraft' && event.data.data) {
        const draft = event.data.data;
        // Check if the draft object has any of the EMAIL draft attributes like for example "to"
        // If it has any of them it means the draft is an email draft
        // If it does not has it it means its a Text draft

        if (draft.hasOwnProperty('to')) {
          setFrom(draft.from);
          setTo(draft.to);
          setCc(draft.cc);
          setBcc(draft.bcc);
          setAttachments(draft.attachments);
          if (draft.body && draft.body !== '') {
            setBodyDraft(draft.body);
          }
          setSubjectDraft(draft.subject);
          handleOpenEmailMode();
          setEmailConversationHasDraft(true);
        } else {
          setTextDraft(draft.text);
        }
      }
    };

    return () => {
      dbWorker.onmessage = null;
    };
  }, []);

  // On first load of a conversation: it scrolls to bottom after the messages are loaded
  // On adding new messages when the user scrolls at the top: it keeps the scroll position
  useEffect(() => {
    const current = messageContainerRef.current;

    if (unreadMessagesIds.length === 0 && loadedMoreMessages.status && current) {
      const newContentHeight =
        current.scrollHeight - loadedMoreMessages.scrollHeight;

      current.scrollTop = loadedMoreMessages.scrollPosition + newContentHeight;

      setLoadedMoreMessages({
        status: false,
        scrollPosition: 0,
        scrollHeight: 0,
      });

      return;
    }

    if ((unreadMessagesIds.length === 0 || isScrollAtBottom) && filesDoneLoading) {
      current?.scrollTo({
        top: current.scrollHeight,
      });
    }

    return () => {
      setFilesDoneLoading(false);
    };
  }, [
    // messages,
    filesDoneLoading,
  ]);

  useEffect(() => {
    setFirstLoad(true);
    setUnreadMessagesIds([]);
    setFirstUnreadMessageId(null);
  }, [conversationSelected]);

  useEffect(() => {
    if (contactId) {
      setMessagesOfContact();
      resetConversation();
      if (conversationSelected) {
        dispatch(setConversationSelected(null));
        setIsEmailMode(false);
        setFooterSize('auto');
        setSelectMessagesMode(false);
        setSelectedMessages([]);
      }
    }
  }, [contactId, conversations]);

  useEffect(() => {
    if (contactId) {
      getContact(contactId);
    }
  }, [contactId]);

  useEffect(() => {
    if (conversationSelected) {
      resetConversation();
      reactToConversationSelectedChange(
        conversationSelected.id,
        conversationSelected.contactInfo.id
      );
    }
  }, [conversationSelected?.id]);

  useEffect(() => {
    if (messagesDb && messagesFetchStatus === 'succeeded') {
      const messagesIds = messages
        .map((message) => message.messages.map((msg) => msg.id))
        .flat();

      let newMessages = messagesDb.filter(
        (message) =>
          !messagesIds.includes(message.id) &&
          message.contactId === conversationSelected?.contactInfo.id
      );

      if (!firstLoad || messages.length > 0) {
        const newMessagesIds = newMessages.map((message) => message.id);
        const isMessageFromUser = newMessages.some(
          (message) => message.direction === 2
        );
        // Add 'read' attribute to each new message that enters after the first load
        newMessages = newMessages.map((message) => ({ ...message, read: false }));

        // Check if any of the new messages has the "direction" attribute === 2 (it means the message has OUT direction not IN)
        if (!isMessageFromUser) {
          // Set new unread messages ids without erasing the previous ones
          setUnreadMessagesIds((unreadMessagesIds) => [
            ...unreadMessagesIds,
            ...newMessagesIds,
          ]);

          // Check if its the first time there is unread messages in the state (firstUnreadMessageId === false)
          // and set the first unread message id
          if (!firstUnreadMessageId) {
            setFirstUnreadMessageId(newMessagesIds[0]);
          } else if (firstUnreadMessageId && isMessageFromUser) {
            // Reset the unread messages alert if the user sends a message
            setFirstUnreadMessageId(null);
          }
        }
      }
      // This only takes in account new messages but not updates
      if (newMessages.length > 0) {
        const oldMessages = messages.map((message) => message.messages).flat();
        const messagesGrouped = groupMessages([...oldMessages, ...newMessages]);
        setMessages(messagesGrouped);
      }
    }
  }, [messagesDb]);

  // Websocket message subscription
  useEffect(() => {
    const handleReadConversation = (data: IMessage) => {
      if (
        isScrollAtBottom &&
        conversationSelectedRef?.current &&
        conversationSelectedRef?.current.id === data.conversationId &&
        conversationSelected
      ) {
        dispatch(
          readConversation({
            conversationId: data.conversationId,
            type:
              conversationSelectedRef.current.type === ChatType.GROUP ||
              conversationSelectedRef.current.type === ChatType.DIRECT
                ? 'internal'
                : 'external',
          })
        );
      }
    };

    if (socketConnection) {
      // subscribeEvent('message_new', async (data) => {
      //   handleReadConversation(data);

      //   await dbWorker.postMessage({
      //     action: 'setMessage',
      //     data: data,
      //   });
      // });
      subscribeEvent('message_update', async (data) => {
        await dbWorker.postMessage({
          action: 'updateMessage',
          data: data,
        });
      });
    }

    return () => {
      // Unsubscribe from the event when the component unmounts because if not it will overlap the next time the component mounts
      // and it will trigger the event multiple times causing repeated messages in the state.
      unsubscribeEvent('message_new');
    };
  }, [socketConnection]);

  return (
    <Box
      sx={{
        ...style.container,
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: theme.palette.mode == 'dark' ? '' : '#F0EBE3',
      }}
      ref={containerRef}
    >
      {!isScrollAtBottom && (
        <ArrowDownButton
          handleScrollToBottom={handleScrollToBottom}
          // newMessageCount={newMessageCount}
          unreadMessagesIds={unreadMessagesIds}
          showSnackbar={showSnackbar}
          setShowSnackbar={setShowSnackbar}
        />
      )}
      {newMessageCount > 0 && (
        <NewMessages
          isScrollAtBottom={isScrollAtBottom}
          onChipClick={handleScrollToBottom}
        />
      )}
      {(isNextLoading || isLoading) && (
        <Box sx={style.progressContainer}>
          <LinearProgress />
        </Box>
      )}
      {useMemo(
        () => (
          <Box
            sx={style.secondaryContainer}
            onScroll={handleScroll}
            ref={messageContainerRef}
          >
            {messages &&
              messages.length > 0 &&
              messages?.map((message, i) => (
                <Box
                  key={i}
                  paddingTop={1}
                  paddingBottom={1}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      pr: 1,
                      pl: 1,
                      position: 'sticky',
                      top: '1rem',
                      zIndex: 10,
                    }}
                  >
                    <Date
                      day={message.date}
                      today={todayFormat}
                      yesterday={yesterday}
                      containerRef={containerRef}
                    />
                  </Box>

                  {message.messages.map((message) => {
                    if (
                      message.type === MessageType.INFO &&
                      !infoMessagesHandler.showMessages
                    ) {
                      return null;
                    }

                    return (
                      <Fragment key={message.id}>
                        {firstUnreadMessageId === message.id && <NewMessagesLabel />}
                        <MessageItem
                          setUnreadMessagesIds={setUnreadMessagesIds}
                          handleSelectMessage={handleSelectMessage}
                          selectMessagesMode={selectMessagesMode}
                          selectedMessages={selectedMessages}
                          message={message}
                          setFilesDoneLoading={setFilesDoneLoading}
                        />
                      </Fragment>
                    );
                  })}
                </Box>
              ))}
          </Box>
        ),
        [
          messages,
          containerRef,
          messageContainerRef,
          isLoading,
          isNextLoading,
          selectMessagesMode,
          selectedMessages,
          contactId,
          infoMessagesHandler.showMessages,
        ]
      )}
    </Box>
  );
};

export default Body;
